import Vue from 'vue';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { get, map, filter, cloneDeep, set, forIn } from 'lodash';
import { randId } from '@/utils/components';
import {
  normalizeFieldValue,
  populateFormItem,
  setDeckPropertyValue,
} from '@/utils/dynamic-registration';
import store from '@/store';
import { fieldIndex } from '@/components/form/utils';
import { AppEnum } from '@/types/enum.types';
import { AUTH_SRC } from '@/constants';
import {
  DeckConfig,
  DeckItemConfig,
  DynamicConfig,
  DynamicFieldType,
  DynamicFormField,
  ValidationStatus,
} from '@/components/form/dynamic-form.types';
import { GroupedNavigationItem } from '@/types';
import { getValidationStatus, isValueDefined } from '@/utils/dynamic-registration-validation';
import { FeatureFlagsModule } from './feature-flags.module';

// Below code is to be/will be refactored
export interface FormSections {
    name: string,
    text: string,
    key: string,
    value?: DynamicFormField[],
    children?: FormSections[],
    route?: any,
    valid?: boolean,
}

interface DynamicFormNavItem {
  name: string;
  label: string;
}

export interface DynamicRegistrationState {
    dynamicFormData: DynamicFormField[];
}

function attachIds(sections: DynamicFormField[]) {
    sections.forEach((section: any) => {
        section.sectionId = randId();
    })
    return sections;
}

async function customValidator(v: DynamicFormField, fieldIndex: Map<string, DynamicFormField>, env: AppEnum, resource: any, 
  dataContext: any, isNotFormValidation?: boolean,proxyPathList? :any, platform?:string): Promise<string> {
  let valid = true;
  const { value, validators = [], required, label, pattern, dataType } = v;

  let errTxt = '';

  let mode = 'create';
  if (get(resource, 'isEdit') === true) mode = 'edit';
  if (get(resource, 'isPromote') === true) mode = 'promote';

  if (
    ('display' in v && v.display === false) ||
    (v.display && typeof v.display === 'function' && !v.display(fieldIndex, mode, env.name, platform))
  ) {
    return '';
  }

  if (required) {
    if (typeof required === 'boolean') {
      valid = isValueDefined(value);
      errTxt = 'The ' + label + ' field is required';
    } else if (typeof required === 'function') {
      if (required(fieldIndex, mode, env.name, dataContext)) {
        valid = isValueDefined(value);
        errTxt = 'The ' + label + ' field is required';
      }
    }
  }
  if (valid && pattern && isValueDefined(value)) {
    if (dataType === 'array') {
      const arr = Array.isArray(value) ? value : value.split(',');
      arr.forEach((item: any) => {
        if (valid) {
          valid = !!item.toString().match(pattern);
          errTxt = v.errorText || '';
        }
      });
    } else {
      valid = !!value.toString().match(pattern);
      errTxt = v.errorText || '';
    }
  }
  if (valid && isValueDefined(value)) {
    for (const validator of validators) {
      if (valid) {
        if (validator.validate) {
          let val = normalizeFieldValue(v, value);
          if (typeof val === 'string' && Number.isInteger(parseInt(val))) {
            val = Number(val);
          }
          valid = valid ? validator.validate(v, val, null, fieldIndex) : valid;
        }
        if (valid) {
          valid = await getValidationStatus(validator.key, value, env.mnemonic, resource, fieldIndex, isNotFormValidation,proxyPathList);
        }
        if (!valid) errTxt = validator.errorText || '';
      }
    }
  }
  return Promise.resolve(valid ? '' : errTxt);
}

function getFormItem(item: DynamicFormField, sectionId: string | undefined, oAuthVal: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { name } = item;
    const { type, val } = getFormCmpTypeAndDefaultVal(item);
    item.key = name;
    if(type !== undefined) {
      item.type = type;
    }
    item.value = val;
    item.parentSectionId = sectionId;
    item.id = randId();
    item.customValidator = customValidator;
    return item;
}

function getFormCmpTypeAndDefaultVal(item: DynamicFormField) {
  const { dataType, type } = item;
  const value: {
      type?: DynamicFieldType,
      val?: string| boolean | any[] | any ,
  } = {};
  switch(dataType) {
      case 'array':
          if(type === 'textfield') {
            value.val = item.default || '';
            value.type = 'input-chips';
          } else if(type === 'input-chips' ||  type === 'radio') {
            value.val = item.default || '';
            value.type = type;
          } else {
            value.val = item.default || [];
            value.type = type;
          }
          break;
      case 'boolean':
          value.val = item.default || false;
          value.type = type;
          break;
      case 'number':
          if(type === 'textfield') {
            value.val = item.default || 0;
            value.type = 'number';
          } else{
            value.val = item.default || 0;
            value.type = type;
          }
          break;
      case 'string':
      default:
          value.val = item.default || '';
          value.type = type;
  }
  return value;
}

function createDynamicFormData(config: DynamicConfig, items: DynamicFormField[], sectionId: string | undefined, oAuthVal: string) {
    const data = map(items, (item: DynamicFormField) => {
        const sId = item.sectionId || sectionId;
        const { itemDefinitions } = config;
        const def = item.itemRef? Object.assign(item, cloneDeep(itemDefinitions[item.itemRef])) :item;
        if(def.items && def.items.length > 0) {
            createDynamicFormData(config, def.items, sId, oAuthVal);
        }
        return getFormItem(def, sId, oAuthVal);
    });
    return data;
}

function flattenTree(tree: DynamicFormField[]): DynamicFormField[]{
    return tree.flatMap(item => item.items ? [item, ...flattenTree(item.items)] : item);
}

function activeItems(formSections: FormSections[], selectedFormSectionKey: string) {
  const match: FormSections | undefined = formSections.find(
    item => item.key === selectedFormSectionKey,
  );
  return match?.value;
}

@Module({ name: 'dynamicregform', store, dynamic: true })
class DynamicRegForm extends VuexModule implements DynamicRegistrationState {
  dynamicFormData: DynamicFormField[] = [];

  formSections: FormSections[] = [];

  navItems: DynamicFormNavItem[] = [];

  deckConfig: DeckConfig = {
    services: [
      {
        name: '',
        routes: [
          {
            name: 'Route1',
            paths: ['/default'],
          },
        ],
      },
    ],
  };

  openApiSpec = undefined;

  publishApiListing = false;

  selectedFormSectionKey = 'simple';

  oAuthMnemonic = '';

  dataChangeStatus = false;

  simpleMode = true;

  resources = {
    name: '',
    path: '',
    isEdit: false,
    versions: [],
    isPromote: false
  };

  showOnlyFieldsWithNames: string[] = [];

  @Mutation
  public setShowFieldsWithNames(fieldNameArr: string[]): void {
    this.showOnlyFieldsWithNames = fieldNameArr;
  }

  @Mutation
  public resetShowFieldsWithNames(): void {
    this.showOnlyFieldsWithNames = [];
  }

  @Mutation
  public setSimpleMode(payload: boolean) {
    this.simpleMode = payload;
    if (get(this.deckConfig, 'services.length', 0) > 0) {
      if (!this.deckConfig.services[0].customProps) this.deckConfig.services[0].customProps = {};
      this.deckConfig.services[0].customProps.formMode = payload ? 'simple' : 'advanced';
    }
  }

  @Mutation
  public updateRegFormData(deckConfig?: any) {
    this.formSections.forEach(section => {
      if (
        (this.simpleMode && section.key === 'simple') ||
        (!this.simpleMode && section.key !== 'simple')
      ) {
        const dynamicFormData = activeItems(this.formSections, section.key) || [];
        const data = flattenTree(dynamicFormData);
        const fields = data.filter(d => d.itemType === 'field');
        const {services} = deckConfig || this.deckConfig;
        const [service] = services;

        fields.forEach(field => populateFormItem(field, service, fieldIndex(fields)));
      }
    });
  }

  @Action
  public createDeckByFormDefinition() {
    const deckConfig = JSON.parse(JSON.stringify(this.deckConfig));

    this.formSections.forEach(section => {
      if (
        (this.simpleMode && section.key === 'simple') ||
        (!this.simpleMode && section.key !== 'simple')
      ) {
        const dynamicFormData = activeItems(this.formSections, section.key) || [];
        const data = flattenTree(dynamicFormData);
        const fields = data.filter(d => d.itemType === 'field');
        fields.forEach(field => {
          const value = field.value !== undefined ? field.value : field.default;
          setDeckPropertyValue(field, value, deckConfig, section.key, this.formSections);
        });
      }
    });
    this.setDeckConfig(deckConfig);
  }

  @Mutation
  public resetDeck(): void {
    this.deckConfig = {
      services: [
        {
          name: '',
          routes: [
            {
              name: 'Route1',
              paths: ['/default'],
            },
          ],
          customProps: {
            formMode: this.simpleMode ? 'simple' : 'advanced',
          },
        },
      ],
    };
  }

  @Mutation
  public setselectedFormSectionKey(selectedFormSectionKey: string): void {
    this.selectedFormSectionKey = selectedFormSectionKey;
  }

  @Mutation
  public setOAuthMnemonic(mnemonic: string): void {
    this.oAuthMnemonic = mnemonic;
  }

  @Mutation
  public setDynamicRegFormData(dynamicFormData: DynamicFormField[]): void {
    this.dynamicFormData = dynamicFormData;
  }

  @Mutation
  public setDataChangeStatus(dataChangeStatus: boolean): void {
    this.dataChangeStatus = dataChangeStatus;
  }

  @Mutation
  public setValidationStatus(validationItems: ValidationStatus[]): void {
    const isValid = (key: string) => {
      return (
        validationItems &&
        validationItems.find((item: ValidationStatus) => item.key === key)?.isValid
      );
    };
    this.formSections.forEach((item) => {
      item.valid = !isValid(item.key);
    });
  }

  @Mutation
  public setDeckConfig(data: DeckConfig): void {
    Vue.set(this, 'deckConfig', data);
  }

  @Mutation
  public setResourceData(data: {name: string, path: string, isEdit: boolean, isPromote: boolean, versions: number[]}): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.resources = data;
  }

  @Mutation
  public resetResourceData(): void {
    this.resources = {
      name: "",
      path: "",
      isEdit: false,
      isPromote: false,
      versions: [],
    };
  }

  @Mutation
  public setFormData(data: FormSections[]): void {
    Vue.set(this, 'formSections', data);
  }

  @Mutation
  public setNavItems(data: DynamicFormNavItem[]): void {
    Vue.set(this, 'navItems', data);
  }

  @Mutation
  public updateRegFormDataById(dataItem: DynamicFormField) {
    const dynamicFormData = activeItems(this.formSections, this.selectedFormSectionKey) || [];
    const data = flattenTree(dynamicFormData);
    const matchedItems = filter(data, (item)=> item && item.id === dataItem.id);
    matchedItems.forEach((item)=> {
      item.value = dataItem.value;
    });
  }

  @Mutation
  public setOpenApiSpec(spec: any) {
    this.openApiSpec = spec;
  }

  @Mutation
  public setPublishApiListing(val: boolean) {
    this.publishApiListing = val;
  }

  @Action({ commit: 'setselectedFormSectionKey' })
  public updateSelectedFormSectionKey(setselectedFormSectionKey: string) {
   return setselectedFormSectionKey;
  }

  @Action({ commit: 'setDataChangeStatus' })
  public updateDataChangeStatus(dataChangeStatus: boolean) {
   return dataChangeStatus;
  }

  @Action({ commit: 'setValidationStatus' })
  public updateValidationStatus(validationItems: ValidationStatus[]): ValidationStatus[] {
   return validationItems;
  }

  @Action({ commit: 'setNavItems' })
  public async createNavItems(config: DynamicConfig) {
    return config.navItems;
  }

  @Mutation
  public updateDefaultAuthType() :void {
    if (get(this.deckConfig, 'services.length', 0) > 0) {
      const auth_type = AUTH_SRC[this.oAuthMnemonic]?.apiRegistrationEnum;
      if (!this.deckConfig.services[0].customProps) this.deckConfig.services[0].customProps = {};
      this.deckConfig.services[0].customProps.auth_type = auth_type;
    }
  }

  @Mutation
  public updateDefaultValuesFromOpenApiSpec() :void {
    if(this.openApiSpec !== undefined) {
      const methods: string[] = [];
      const paths = get(this.openApiSpec, 'paths',[]);
      const info = get(this.openApiSpec, 'info',{});
      const servers = get(this.openApiSpec, 'servers[0]',{});
      forIn(paths, (path: any) => {
        const keysInPath = Object.keys(path);
        if(keysInPath.length > 0) {
          keysInPath.forEach((key: string) => {
            if(methods.indexOf(key) === -1) methods.push(key.toUpperCase())
          });
        }
      });
      let url = {};
      try {
        url = new URL(get(servers, 'url',''));
      }
      catch(e) {
        console.error(e);
      }
      const service = this.deckConfig.services[0];
      if (!service.customProps) service.customProps = {};
      const routes = service.routes![0];
      const customProps = service.customProps;
      const protocol = get(url, 'protocol', '').replace(":", "");
      const port = get(url, 'port', '');
      const defaultPort = protocol === 'https'? 443: 80
      set(customProps, 'resourceName', get(info, 'title','').replace(/ /g,""));
      set(customProps, 'description', get(info, 'description',''));
      set(routes, 'paths', [`/${get(info, 'title','').toLowerCase().replace(/ /g,"-")}`]);
      set(routes, 'methods', [...new Set(methods)]);
      set(service, 'host', get(url, 'host', ''));
      set(service, 'protocol', protocol);
      set(service, 'port', port === ''? defaultPort: port);
      set(service, 'path', get(url, 'pathname', ''));
      this.simpleMode = false;
    }
  }

  @Action({ commit: 'setFormData' })
  public async createNewDynamicForm(config: DynamicConfig): Promise<FormSections[]> {
    await this.createNavItems(config);

    const form: FormSections[] = this.navItems.map(navItem => ({
      name: navItem.name,
      text: navItem.label,
      key: navItem.name,
      value: [],
      valid: true,
    }));

    form.push({
      name: 'simple',
      text: 'SimpleView',
      key: 'simple',
      value: [],
      valid: true,
    });

    form.forEach(item => {
      const sectionsWithIds = attachIds(config.sections);
      item.value = cloneDeep(createDynamicFormData(config, sectionsWithIds, undefined, this.oAuthMnemonic));
      item.value = item.value.filter(i => !i.restrictTo || i.restrictTo === item.key);
      return item;
    });
    return form;
  }

  @Action({ commit: 'setOAuthMnemonic' })
  public async setOAuthMnemonicVal(mnemonic: string): Promise<any> {
    return mnemonic;
  }

  @Action({ commit: 'setOpenApiSpec' })
  public async setOpenApiSpecVal(spec: any) {
    return spec;
  }

  @Action({ commit: 'setPublishApiListing' })
  public async setPublishApiListingVal(val: boolean) {
    return val;
  }

  public get activeFormSectionItems() {
   return activeItems(this.formSections, this.selectedFormSectionKey)
  }

  public get allFormSectionItems() {
    return this.formSections;
  }

  public get oAuthMnemonicVal() {
    return this.oAuthMnemonic;
  }

  get isOpenApiSpecEnabled() {
    return FeatureFlagsModule.openApiSpecEnabled;
  }

  public get leftNavItems() {
    return this.navItems.map(navItem => {
      const valid = this.formSections.find(section => section.key === navItem.name)?.valid;
      return {
        key: navItem.name,
        text: navItem.label,
        disabled: navItem.name === 'documentation'? !this.isOpenApiSpecEnabled: false,
        valid,
      } as GroupedNavigationItem;
    });
  }

  public get resourceData() {
    return this.resources;
  }

  public get openApiSpecData() {
    return this.openApiSpec;
  }

  public get publishApiListingData() {
    return this.publishApiListing;
  } 

}

export const DynamicRegFormModule = getModule(DynamicRegForm);
