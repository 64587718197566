import { get, isArray } from 'lodash';
import { validateResourceName, validateProxyPath } from '@/api/registration.api';
import { normalizeUrlPath } from '@/views/api-registration/registration.helpers';
import { DynamicFormField } from '@/components/form/dynamic-form.types';
import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let proxyPathSource = CancelToken.source();
let resourceNameSource = CancelToken.source();

let proxyPathController = new AbortController();
let resourceNameController = new AbortController();

export function isValidUrl(url: string) {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // validate protocol
    '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}' + // validate domain name
    '(:\\d+)?(\\/[\\w\\-.:%]+)*\\/?' + // validate port and path
    '(?!.*\\/\\?)(?!.*[\\?#])(?!.*#).*$',  // validate query string
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(url);
}

export function isValidOrigin(url: string) {
  const urlPattern = new RegExp('^(?:https?://)?(?:[-\\w]*(?:\\*(?=\\.))?(?:\\.[-\\w]{1,63})+?(:[0-9]{4})?)$', 'gm')
  const localhostPattern = new RegExp('^(?:https?://)?(localhost):?([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])?$', 'gm')
  return !!urlPattern.test(url) || !!localhostPattern.test(url);
}

export async function getValidationStatus(
  key: string,
  value: any,
  envMnemonic: string,
  resource: any,
  fieldIndex: Map<string, DynamicFormField>,
  isNotFormValidation? : boolean,
  proxyPathList? : any,
) {
  let valid = true;
  if (key === 'isUrl') {
    valid = isValidUrl(value);
  }
  else if(key === 'validOrigin') {
    const arr = Array.isArray(value) ? value : value.split(',');
    if(arr.indexOf('*') !== -1 && arr.length ==1) {
      valid = true;
    } else if(arr.indexOf('*') !== -1 && arr.length > 1) {
      valid = false;
    } else {
      valid = arr.some((i: string) => { return ! isValidOrigin(i) })? false: true;
    }
  } else if (key === 'validServiceName') {
    const isValAndEnvDefined = value.trim().length > 0 && envMnemonic.length > 0;
    const rName = get(resource, 'name', '');
    try {
      if (isValAndEnvDefined && (rName === '' || rName !== value)) {
        if(isNotFormValidation) {
          if(resourceNameSource.cancel) resourceNameSource.cancel();
          resourceNameSource = CancelToken.source();
          if(resourceNameController) resourceNameController.abort();
          resourceNameController = new AbortController();
        }
        valid = (await validateResourceName(value, envMnemonic, resourceNameController, resourceNameSource.token)).data;
      }
    } catch(e) {
      //
    }

  } else if (key === 'validServiceVersion') {
    const versions = get(resource, 'versions', []);
    if (Array.isArray(versions)) {
      valid = versions.indexOf(parseInt(value)) === -1;
    }
  } else if (key === 'validKongProxyPath') {
    const rPath = normalizeUrlPath(get(resource, 'path', ''), true);
    const isValAndEnvDefined = value.trim().length > 0 && envMnemonic.length > 0;
    const isEdit = get(resource, 'isEdit', false);
    const isPromote = get(resource, 'isPromote', false);
    const nextEnv = envMnemonic == 'DEV' ? 'QA' : 'PROD';
    try {
      if (valid && isValAndEnvDefined && rPath !== '') {
        if (isNotFormValidation) {
          if (proxyPathSource.cancel) proxyPathSource.cancel();
          proxyPathSource = CancelToken.source();
          if (proxyPathController) proxyPathController.abort();
          proxyPathController = new AbortController();
        }
        if (!(isEdit && rPath === value) && !isPromote) {
          valid = await checkProxyPathUniqueness(
            normalizeUrlPath(value, true),
            proxyPathController,
          );
        } else if (isPromote) {
          valid = await checkProxyPathUniqueness(
            normalizeUrlPath(value, true),
            proxyPathController,
            nextEnv,
          );
        }
      }
    } catch(e) {
      //
    }

  } else if (key === 'validIPAddresses') {
    const IPRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9][0-9])?$/;

    const IPV6Regex =
      /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/((1(1[0-9]|2[0-8]))|([0-9][0-9])|([0-9])))?$/;
    const IPs = Array.isArray(value) ? value : value.split(',');
    if (IPs.some((ip: string) => !ip.match(IPRegex) && !ip.match(IPV6Regex))) {
      valid = false;
    }
  } else if (key === 'validApiKey') {
    const regex = /^[a-zA-Z0-9_-]+$/;
    const list = Array.isArray(value) ? value : value.split(',');
    if (list.some((item: string) => !item.match(regex))) {
      valid = false;
    }
  }
  return valid;
}

export const isValueDefined = (value: any) => {
  if (isArray(value)) {
    return value.length > 0;
  } else if (typeof value === 'number') {
    return true;
  } else if (typeof value === 'object') {
    return 'value' in value && !!value['value'];
  } else {
    return !!value;
  }
};

export async function checkProxyPathUniqueness(
  proxyPath: string,
  abortController?: AbortController,
  nextEnv?: string,
) {
  const paths = await validateProxyPath(proxyPath, abortController);
  if(!paths || !paths.data){
    return !!paths;
  }
  const data = paths.data as string[];
  return nextEnv ? !data?.includes(nextEnv) : data.length === 0;
}
