import { set } from 'lodash';
import { fieldIndex, getSectionById } from '@/components/form/utils';
import { FormSections } from '@/store/modules/dynamic-registration.module';
import type { DynamicFormField, DeckConfig } from '@/components/form/dynamic-form.types';
import * as types from '@/components/form/form.types';
import { PluginConfig } from '@/components/form/dynamic-form.types';
import { randId } from './components';

type CssClassItemDefinition = {
  name: string;
  property: string;
};

type CssClassDefinition = {
  label: string;
  description: string;
  classes: CssClassItemDefinition[];
};

export function getPropertyValue(
  dataContext: any,
  field: DynamicFormField,
  fieldIndex: Map<string, DynamicFormField>,
) {
  let prop = field.property;

  if (!prop) return;

  if (typeof prop !== 'object') {
    const propParts = prop.split('.');
    if (propParts.length > 1) {
      const base = propParts[0];
      if (!dataContext[base]) {
        dataContext[base] = {};
      }
      dataContext = dataContext[base];
      prop = propParts[1];
    }
  }
  let val: any;
  if (typeof prop === 'object') {
    val = field.property.in(field, field.value, dataContext, fieldIndex);
  } else {
    val = dataContext?.[prop];

    if (
      (field.type === 'input-chips' || field.type === 'textfield') &&
      field.dataType === 'array' &&
      Array.isArray(val)
    ) {
      val = val.join(',');
    }
    // Fix textfield's undefined value error
    if (field.type === 'textfield' && (val === null || typeof val === 'undefined')) {
      val = '';
    }
  }
  if (!(val === undefined || val === null)) {
    return val;
  } else if (typeof field.default !== 'undefined') {
    return field.default;
  }
}

export function getDeckPropertyValue(
  field: DynamicFormField,
  dataContext: any,
  fieldIndex: Map<string, DynamicFormField>,
): any {
  let value;

  // Set Field Value
  if (field.plugin) {
    let pluginData: PluginConfig;

    if (field.pluginScope === 'route') {
      pluginData = dataContext?.routes?.[0].plugins?.find(
        (ref: PluginConfig) => ref.name === field.plugin,
      );
    } else {
      pluginData = dataContext?.plugins?.find((ref: PluginConfig) => ref.name === field.plugin);
    }

    if (pluginData) {
      value = getPropertyValue(pluginData, field, fieldIndex);
    }
  } else {
    value = getPropertyValue(dataContext, field, fieldIndex);
  }
  return value;
}

export function populateFormItem(
  field: DynamicFormField,
  dataContext: any,
  fieldIndex: Map<string, DynamicFormField>,
): void {
  const value = getDeckPropertyValue(field, dataContext, fieldIndex);
  populateFormDefaults(value, field);
  if (value !== undefined) {
    field.value = value;
  }
}

function populateFormDefaults(val: any, field: DynamicFormField) {
  if (field.type === 'key-value' && Array.isArray(val)) {
    val.forEach(v => {
      if (v.id === undefined) {
        v.id = randId();
      }
    });
  }
}

export function normalizeFieldValue(field: DynamicFormField, value: types.FormField['value']) {
  if (field.dataType === 'array' && typeof value === 'string') {
    value = value.split(',');
  } else if (field.dataType === 'number') {
    value = Number(value);
  }

  if (Array.isArray(value)) {
    value = (value as []).filter((v: any) => v);
  }

  if (field.valueModifier) {
    switch (field.valueModifier) {
      case 'parseInt':
        if (Array.isArray(value)) {
          value = value.map((v: any) => {
            if (typeof v === 'string' && Number.isInteger(parseInt(v))) v = parseInt(v);
            return v;
          });
        } else if (typeof value === 'string' && Number.isInteger(parseInt(value))) {
          value = parseInt(value);
        }
        break;
    }
  }
  return value;
}

export function setDeckPropertyValue(
  field: DynamicFormField,
  value: types.FormField['value'],
  deckConfig: DeckConfig,
  activeSectionKey: string,
  formSections: FormSections[],
): void {
  value = normalizeFieldValue(field, value);
  let itemToBeUpdated = deckConfig.services[0];

  if (!itemToBeUpdated) return;

  const isPlugin = !!field.plugin;

  if (isPlugin) {
    const plugin = field.plugin as string;

    if (field.pluginScope === 'route') {
      if (deckConfig.services?.[0]?.routes?.[0]) {
        itemToBeUpdated = deckConfig.services[0].routes[0];
      }
      if (!itemToBeUpdated) return;
    }

    if (!itemToBeUpdated.plugins) {
      itemToBeUpdated.plugins = [];
    }

    let pluginDef = itemToBeUpdated.plugins.find((ref: PluginConfig) => ref.name === plugin);
    if (!pluginDef) {
      pluginDef = { name: plugin, config: {}, enabled: true };
      itemToBeUpdated.plugins.push(pluginDef);
    }
    if (typeof field.property === 'object') {
      if (field.property?.out) {
        let updates;
        try {
          const parentSectionId = field.parentSectionId;
          const section = getSectionById(formSections, parentSectionId);
          const fieldIdx = section?.items
            ? fieldIndex(section.items)
            : new Map<string, DynamicFormField>();

          field.property.out(field, value, itemToBeUpdated, fieldIdx, pluginDef);
        } catch (e) {
          updates = null;
        }
      }
    } else {
      set(pluginDef, field.property, value);
    }
  } else if (field.property) {
    if (typeof field.property === 'object') {
      if (field.property?.out) {
        let updates;
        try {
          const parentSectionId = field.parentSectionId;
          const section = getSectionById(formSections, parentSectionId);
          const fieldIdx = section?.items
            ? fieldIndex(section.items)
            : new Map<string, DynamicFormField>();

          updates = field.property.out(field, value, itemToBeUpdated, fieldIdx);
          Object.assign(itemToBeUpdated, updates);
        } catch (e) {
          updates = null;
        }
      }
    } else {
      set(itemToBeUpdated, field.property, value);
    }
  }
}

export function getCssClasses(): CssClassDefinition[] {
  return [
    {
      label: 'Typography',
      description: "Beautiful typographic defaults for HTML you don't control.",
      classes: [
        { name: 'gsk-typography--headline1', property: 'Sets font properties as Headline 1' },
        { name: 'gsk-typography--headline2', property: 'Sets font properties as Headline 2' },
        { name: 'gsk-typography--headline3', property: 'Sets font properties as Headline 3' },
        { name: 'gsk-typography--headline4', property: 'Sets font properties as Headline 4' },
        { name: 'gsk-typography--headline5', property: 'Sets font properties as Headline 5' },
        { name: 'gsk-typography--headline6', property: 'Sets font properties as Headline 6' },
        { name: 'gsk-typography--subtitle1', property: 'Sets font properties as Subtitle 1' },
        { name: 'gsk-typography--subtitle2', property: 'Sets font properties as Subtitle 2' },
        { name: 'gsk-typography--body1', property: 'Sets font properties as Body 1' },
        { name: 'gsk-typography--body2', property: 'Sets font properties as Body 2' },
        { name: 'gsk-typography--caption', property: 'Sets font properties as Caption' },
        {
          name: 'gsk-typography--button',
          property: 'Sets font properties as Button (Deprecated, Will be removed soon)',
        },
        { name: 'gsk-typography--button1', property: 'Sets font properties as Button 1' },
        { name: 'gsk-typography--button2', property: 'Sets font properties as Button 2' },
        { name: 'gsk-typography--overline', property: 'Sets font properties as Overline' },
      ],
    },
    {
      label: 'Display',
      description: 'Utilities for controlling the display box type of an element.',
      classes: [
        { name: 'block', property: 'display: block' },
        { name: 'inline', property: 'display: inline' },
        { name: 'flex', property: 'display: flex' },
        { name: 'hidden', property: 'display: hidden' },
      ],
    },
    {
      label: 'Position',
      description: 'Utilities for controlling how an element is positioned in the DOM.',
      classes: [
        { name: 'static', property: 'position: static' },
        { name: 'fixed', property: 'position: fixed' },
        { name: 'absolute', property: 'position: absolute' },
        { name: 'relative', property: 'position: relative' },
        { name: 'sticky', property: 'position: sticky' },
      ],
    },
    {
      label: 'Flex',
      description: 'Utilities for controlling how flex items both grow and shrink.',
      classes: [
        { name: 'grow', property: 'flex-grow: 1' },
        { name: 'grow-0', property: 'flex-grow: 0' },
        { name: 'shrink', property: 'flex-shrink: 1' },
        { name: 'shrink-0', property: 'flex-shrink: 0' },
      ],
    },
    {
      label: 'Justify Content',
      description:
        "Utilities for controlling how flex and grid items are positioned along a container's main axis.",
      classes: [
        { name: 'justify-normal', property: 'justify-content: normal' },
        { name: 'justify-start', property: 'justify-content: flex-start' },
        { name: 'justify-end', property: 'justify-content: flex-end' },
        { name: 'justify-center', property: 'justify-content: center' },
        { name: 'justify-between', property: 'justify-content: space-between' },
        { name: 'justify-around', property: 'justify-content: space-around' },
        { name: 'justify-evenly', property: 'justify-content: space-evenly' },
        { name: 'justify-stretch', property: 'justify-content: stretch' },
      ],
    },
    {
      label: 'Align Items',
      description:
        "Utilities for controlling how flex and grid items are positioned along a container's cross axis.",
      classes: [
        { name: 'items-start', property: 'align-items: flex-start' },
        { name: 'items-end', property: 'align-items: flex-end' },
        { name: 'items-center', property: 'align-items: center' },
        { name: 'items-baseline', property: 'align-items: baseline' },
        { name: 'items-stretch', property: 'align-items: stretch' },
      ],
    },
    {
      label: 'Padding',
      description: "Utilities for controlling an element's padding.",
      classes: [
        { name: 'p-0', property: 'padding: 0px' },
        { name: 'px-0', property: 'padding-left: 0px; padding-right: 0px' },
        { name: 'py-0', property: 'padding-top: 0px; padding-bottom: 0px' },
        { name: 'pt-0', property: 'padding-top: 0px' },
        { name: 'pr-0', property: 'padding-right: 0px' },
        { name: 'pb-0', property: 'padding-bottom: 0px' },
        { name: 'pl-0', property: 'padding-left: 0px' },
        { name: 'p-px', property: 'padding: 1px' },
        { name: 'px-px', property: 'padding-left: 1px; padding-right: 1px' },
        { name: 'py-px', property: 'padding-top: 1px; padding-bottom: 1px' },
        { name: 'pt-px', property: 'padding-top: 1px' },
        { name: 'pr-px', property: 'padding-right: 1px' },
        { name: 'pb-px', property: 'padding-bottom: 1px' },
        { name: 'pl-px', property: 'padding-left: 1px' },
        { name: 'p-0.5', property: 'padding: 0.125rem; /* 2px */' },
        {
          name: 'px-0.5',
          property: 'padding-left: 0.125rem; /* 2px */ padding-right: 0.125rem; /* 2px */',
        },
        {
          name: 'py-0.5',
          property: 'padding-top: 0.125rem; /* 2px */ padding-bottom: 0.125rem; /* 2px */',
        },
        { name: 'pt-0.5', property: 'padding-top: 0.125rem; /* 2px */' },
        { name: 'pr-0.5', property: 'padding-right: 0.125rem; /* 2px */' },
        { name: 'pb-0.5', property: 'padding-bottom: 0.125rem; /* 2px */' },
        { name: 'pl-0.5', property: 'padding-left: 0.125rem; /* 2px */' },
        { name: 'p-1', property: 'padding: 0.25rem; /* 4px */' },
        {
          name: 'px-1',
          property: 'padding-left: 0.25rem; /* 4px */ padding-right: 0.25rem; /* 4px */',
        },
        {
          name: 'py-1',
          property: 'padding-top: 0.25rem; /* 4px */ padding-bottom: 0.25rem; /* 4px */',
        },
        { name: 'pt-1', property: 'padding-top: 0.25rem; /* 4px */' },
        { name: 'pr-1', property: 'padding-right: 0.25rem; /* 4px */' },
        { name: 'pb-1', property: 'padding-bottom: 0.25rem; /* 4px */' },
        { name: 'pl-1', property: 'padding-left: 0.25rem; /* 4px */' },
        { name: 'p-1.5', property: 'padding: 0.375rem; /* 6px */' },
        {
          name: 'px-1.5',
          property: 'padding-left: 0.375rem; /* 6px */ padding-right: 0.375rem; /* 6px */',
        },
        {
          name: 'py-1.5',
          property: 'padding-top: 0.375rem; /* 6px */ padding-bottom: 0.375rem; /* 6px */',
        },
        { name: 'pt-1.5', property: 'padding-top: 0.375rem; /* 6px */' },
        { name: 'pr-1.5', property: 'padding-right: 0.375rem; /* 6px */' },
        { name: 'pb-1.5', property: 'padding-bottom: 0.375rem; /* 6px */' },
        { name: 'pl-1.5', property: 'padding-left: 0.375rem; /* 6px */' },
        { name: 'p-2', property: 'padding: 0.5rem; /* 8px */' },
        {
          name: 'px-2',
          property: 'padding-left: 0.5rem; /* 8px */ padding-right: 0.5rem; /* 8px */',
        },
        {
          name: 'py-2',
          property: 'padding-top: 0.5rem; /* 8px */ padding-bottom: 0.5rem; /* 8px */',
        },
        { name: 'pt-2', property: 'padding-top: 0.5rem; /* 8px */' },
        { name: 'pr-2', property: 'padding-right: 0.5rem; /* 8px */' },
        { name: 'pb-2', property: 'padding-bottom: 0.5rem; /* 8px */' },
        { name: 'pl-2', property: 'padding-left: 0.5rem; /* 8px */' },
        { name: 'p-2.5', property: 'padding: 0.625rem; /* 10px */' },
        {
          name: 'px-2.5',
          property: 'padding-left: 0.625rem; /* 10px */ padding-right: 0.625rem; /* 10px */',
        },
        {
          name: 'py-2.5',
          property: 'padding-top: 0.625rem; /* 10px */ padding-bottom: 0.625rem; /* 10px */',
        },
        { name: 'pt-2.5', property: 'padding-top: 0.625rem; /* 10px */' },
        { name: 'pr-2.5', property: 'padding-right: 0.625rem; /* 10px */' },
        { name: 'pb-2.5', property: 'padding-bottom: 0.625rem; /* 10px */' },
        { name: 'pl-2.5', property: 'padding-left: 0.625rem; /* 10px */' },
        { name: 'p-3', property: 'padding: 0.75rem; /* 12px */' },
        {
          name: 'px-3',
          property: 'padding-left: 0.75rem; /* 12px */ padding-right: 0.75rem; /* 12px */',
        },
        {
          name: 'py-3',
          property: 'padding-top: 0.75rem; /* 12px */ padding-bottom: 0.75rem; /* 12px */',
        },
        { name: 'pt-3', property: 'padding-top: 0.75rem; /* 12px */' },
        { name: 'pr-3', property: 'padding-right: 0.75rem; /* 12px */' },
        { name: 'pb-3', property: 'padding-bottom: 0.75rem; /* 12px */' },
        { name: 'pl-3', property: 'padding-left: 0.75rem; /* 12px */' },
        { name: 'p-3.5', property: 'padding: 0.875rem; /* 14px */' },
        {
          name: 'px-3.5',
          property: 'padding-left: 0.875rem; /* 14px */ padding-right: 0.875rem; /* 14px */',
        },
        {
          name: 'py-3.5',
          property: 'padding-top: 0.875rem; /* 14px */ padding-bottom: 0.875rem; /* 14px */',
        },
        { name: 'pt-3.5', property: 'padding-top: 0.875rem; /* 14px */' },
        { name: 'pr-3.5', property: 'padding-right: 0.875rem; /* 14px */' },
        { name: 'pb-3.5', property: 'padding-bottom: 0.875rem; /* 14px */' },
        { name: 'pl-3.5', property: 'padding-left: 0.875rem; /* 14px */' },
        { name: 'p-4', property: 'padding: 1rem; /* 16px */' },
        {
          name: 'px-4',
          property: 'padding-left: 1rem; /* 16px */ padding-right: 1rem; /* 16px */',
        },
        {
          name: 'py-4',
          property: 'padding-top: 1rem; /* 16px */ padding-bottom: 1rem; /* 16px */',
        },
        { name: 'pt-4', property: 'padding-top: 1rem; /* 16px */' },
        { name: 'pr-4', property: 'padding-right: 1rem; /* 16px */' },
        { name: 'pb-4', property: 'padding-bottom: 1rem; /* 16px */' },
        { name: 'pl-4', property: 'padding-left: 1rem; /* 16px */' },
        { name: 'p-5', property: 'padding: 1.25rem; /* 20px */' },
        {
          name: 'px-5',
          property: 'padding-left: 1.25rem; /* 20px */ padding-right: 1.25rem; /* 20px */',
        },
        {
          name: 'py-5',
          property: 'padding-top: 1.25rem; /* 20px */ padding-bottom: 1.25rem; /* 20px */',
        },
        { name: 'pt-5', property: 'padding-top: 1.25rem; /* 20px */' },
        { name: 'pr-5', property: 'padding-right: 1.25rem; /* 20px */' },
        { name: 'pb-5', property: 'padding-bottom: 1.25rem; /* 20px */' },
        { name: 'pl-5', property: 'padding-left: 1.25rem; /* 20px */' },
        { name: 'p-6', property: 'padding: 1.5rem; /* 24px */' },
        {
          name: 'px-6',
          property: 'padding-left: 1.5rem; /* 24px */ padding-right: 1.5rem; /* 24px */',
        },
        {
          name: 'py-6',
          property: 'padding-top: 1.5rem; /* 24px */ padding-bottom: 1.5rem; /* 24px */',
        },
        { name: 'pt-6', property: 'padding-top: 1.5rem; /* 24px */' },
        { name: 'pr-6', property: 'padding-right: 1.5rem; /* 24px */' },
        { name: 'pb-6', property: 'padding-bottom: 1.5rem; /* 24px */' },
        { name: 'pl-6', property: 'padding-left: 1.5rem; /* 24px */' },
        { name: 'p-7', property: 'padding: 1.75rem; /* 28px */' },
        {
          name: 'px-7',
          property: 'padding-left: 1.75rem; /* 28px */ padding-right: 1.75rem; /* 28px */',
        },
        {
          name: 'py-7',
          property: 'padding-top: 1.75rem; /* 28px */ padding-bottom: 1.75rem; /* 28px */',
        },
        { name: 'pt-7', property: 'padding-top: 1.75rem; /* 28px */' },
        { name: 'pr-7', property: 'padding-right: 1.75rem; /* 28px */' },
        { name: 'pb-7', property: 'padding-bottom: 1.75rem; /* 28px */' },
        { name: 'pl-7', property: 'padding-left: 1.75rem; /* 28px */' },
        { name: 'p-8', property: 'padding: 2rem; /* 32px */' },
        {
          name: 'px-8',
          property: 'padding-left: 2rem; /* 32px */ padding-right: 2rem; /* 32px */',
        },
        {
          name: 'py-8',
          property: 'padding-top: 2rem; /* 32px */ padding-bottom: 2rem; /* 32px */',
        },
        { name: 'pt-8', property: 'padding-top: 2rem; /* 32px */' },
        { name: 'pr-8', property: 'padding-right: 2rem; /* 32px */' },
        { name: 'pb-8', property: 'padding-bottom: 2rem; /* 32px */' },
        { name: 'pl-8', property: 'padding-left: 2rem; /* 32px */' },
        { name: 'p-9', property: 'padding: 2.25rem; /* 36px */' },
        {
          name: 'px-9',
          property: 'padding-left: 2.25rem; /* 36px */ padding-right: 2.25rem; /* 36px */',
        },
        {
          name: 'py-9',
          property: 'padding-top: 2.25rem; /* 36px */ padding-bottom: 2.25rem; /* 36px */',
        },
        { name: 'pt-9', property: 'padding-top: 2.25rem; /* 36px */' },
        { name: 'pr-9', property: 'padding-right: 2.25rem; /* 36px */' },
        { name: 'pb-9', property: 'padding-bottom: 2.25rem; /* 36px */' },
        { name: 'pl-9', property: 'padding-left: 2.25rem; /* 36px */' },
        { name: 'p-10', property: 'padding: 2.5rem; /* 40px */' },
        {
          name: 'px-10',
          property: 'padding-left: 2.5rem; /* 40px */ padding-right: 2.5rem; /* 40px */',
        },
        {
          name: 'py-10',
          property: 'padding-top: 2.5rem; /* 40px */ padding-bottom: 2.5rem; /* 40px */',
        },
        { name: 'pt-10', property: 'padding-top: 2.5rem; /* 40px */' },
        { name: 'pr-10', property: 'padding-right: 2.5rem; /* 40px */' },
        { name: 'pb-10', property: 'padding-bottom: 2.5rem; /* 40px */' },
        { name: 'pl-10', property: 'padding-left: 2.5rem; /* 40px */' },
      ],
    },
    {
      label: 'Margin',
      description: "Utilities for controlling an element's margin.",
      classes: [
        { name: 'm-0', property: 'margin: 0px' },
        { name: 'mx-0', property: 'margin-left: 0px; margin-right: 0px' },
        { name: 'my-0', property: 'margin-top: 0px; margin-bottom: 0px' },
        { name: 'mt-0', property: 'margin-top: 0px' },
        { name: 'mr-0', property: 'margin-right: 0px' },
        { name: 'mb-0', property: 'margin-bottom: 0px' },
        { name: 'ml-0', property: 'margin-left: 0px' },
        { name: 'm-px', property: 'margin: 1px' },
        { name: 'mx-px', property: 'margin-left: 1px; margin-right: 1px' },
        { name: 'my-px', property: 'margin-top: 1px; margin-bottom: 1px' },
        { name: 'mt-px', property: 'margin-top: 1px' },
        { name: 'mr-px', property: 'margin-right: 1px' },
        { name: 'mb-px', property: 'margin-bottom: 1px' },
        { name: 'ml-px', property: 'margin-left: 1px' },
        { name: 'm-0.5', property: 'margin: 0.125rem; /* 2px */' },
        {
          name: 'mx-0.5',
          property: 'margin-left: 0.125rem; /* 2px */ margin-right: 0.125rem; /* 2px */',
        },
        {
          name: 'my-0.5',
          property: 'margin-top: 0.125rem; /* 2px */ margin-bottom: 0.125rem; /* 2px */',
        },
        { name: 'mt-0.5', property: 'margin-top: 0.125rem; /* 2px */' },
        { name: 'mr-0.5', property: 'margin-right: 0.125rem; /* 2px */' },
        { name: 'mb-0.5', property: 'margin-bottom: 0.125rem; /* 2px */' },
        { name: 'ml-0.5', property: 'margin-left: 0.125rem; /* 2px */' },
        { name: 'm-1', property: 'margin: 0.25rem; /* 4px */' },
        {
          name: 'mx-1',
          property: 'margin-left: 0.25rem; /* 4px */ margin-right: 0.25rem; /* 4px */',
        },
        {
          name: 'my-1',
          property: 'margin-top: 0.25rem; /* 4px */ margin-bottom: 0.25rem; /* 4px */',
        },
        { name: 'mt-1', property: 'margin-top: 0.25rem; /* 4px */' },
        { name: 'mr-1', property: 'margin-right: 0.25rem; /* 4px */' },
        { name: 'mb-1', property: 'margin-bottom: 0.25rem; /* 4px */' },
        { name: 'ml-1', property: 'margin-left: 0.25rem; /* 4px */' },
        { name: 'm-1.5', property: 'margin: 0.375rem; /* 6px */' },
        {
          name: 'mx-1.5',
          property: 'margin-left: 0.375rem; /* 6px */ margin-right: 0.375rem; /* 6px */',
        },
        {
          name: 'my-1.5',
          property: 'margin-top: 0.375rem; /* 6px */ margin-bottom: 0.375rem; /* 6px */',
        },
        { name: 'mt-1.5', property: 'margin-top: 0.375rem; /* 6px */' },
        { name: 'mr-1.5', property: 'margin-right: 0.375rem; /* 6px */' },
        { name: 'mb-1.5', property: 'margin-bottom: 0.375rem; /* 6px */' },
        { name: 'ml-1.5', property: 'margin-left: 0.375rem; /* 6px */' },
        { name: 'm-2', property: 'margin: 0.5rem; /* 8px */' },
        {
          name: 'mx-2',
          property: 'margin-left: 0.5rem; /* 8px */ margin-right: 0.5rem; /* 8px */',
        },
        {
          name: 'my-2',
          property: 'margin-top: 0.5rem; /* 8px */ margin-bottom: 0.5rem; /* 8px */',
        },
        { name: 'mt-2', property: 'margin-top: 0.5rem; /* 8px */' },
        { name: 'mr-2', property: 'margin-right: 0.5rem; /* 8px */' },
        { name: 'mb-2', property: 'margin-bottom: 0.5rem; /* 8px */' },
        { name: 'ml-2', property: 'margin-left: 0.5rem; /* 8px */' },
        { name: 'm-2.5', property: 'margin: 0.625rem; /* 10px */' },
        {
          name: 'mx-2.5',
          property: 'margin-left: 0.625rem; /* 10px */ margin-right: 0.625rem; /* 10px */',
        },
        {
          name: 'my-2.5',
          property: 'margin-top: 0.625rem; /* 10px */ margin-bottom: 0.625rem; /* 10px */',
        },
        { name: 'mt-2.5', property: 'margin-top: 0.625rem; /* 10px */' },
        { name: 'mr-2.5', property: 'margin-right: 0.625rem; /* 10px */' },
        { name: 'mb-2.5', property: 'margin-bottom: 0.625rem; /* 10px */' },
        { name: 'ml-2.5', property: 'margin-left: 0.625rem; /* 10px */' },
        { name: 'm-3', property: 'margin: 0.75rem; /* 12px */' },
        {
          name: 'mx-3',
          property: 'margin-left: 0.75rem; /* 12px */ margin-right: 0.75rem; /* 12px */',
        },
        {
          name: 'my-3',
          property: 'margin-top: 0.75rem; /* 12px */ margin-bottom: 0.75rem; /* 12px */',
        },
        { name: 'mt-3', property: 'margin-top: 0.75rem; /* 12px */' },
        { name: 'mr-3', property: 'margin-right: 0.75rem; /* 12px */' },
        { name: 'mb-3', property: 'margin-bottom: 0.75rem; /* 12px */' },
        { name: 'ml-3', property: 'margin-left: 0.75rem; /* 12px */' },
        { name: 'm-3.5', property: 'margin: 0.875rem; /* 14px */' },
        {
          name: 'mx-3.5',
          property: 'margin-left: 0.875rem; /* 14px */ margin-right: 0.875rem; /* 14px */',
        },
        {
          name: 'my-3.5',
          property: 'margin-top: 0.875rem; /* 14px */ margin-bottom: 0.875rem; /* 14px */',
        },
        { name: 'mt-3.5', property: 'margin-top: 0.875rem; /* 14px */' },
        { name: 'mr-3.5', property: 'margin-right: 0.875rem; /* 14px */' },
        { name: 'mb-3.5', property: 'margin-bottom: 0.875rem; /* 14px */' },
        { name: 'ml-3.5', property: 'margin-left: 0.875rem; /* 14px */' },
        { name: 'm-4', property: 'margin: 1rem; /* 16px */' },
        { name: 'mx-4', property: 'margin-left: 1rem; /* 16px */ margin-right: 1rem; /* 16px */' },
        { name: 'my-4', property: 'margin-top: 1rem; /* 16px */ margin-bottom: 1rem; /* 16px */' },
        { name: 'mt-4', property: 'margin-top: 1rem; /* 16px */' },
        { name: 'mr-4', property: 'margin-right: 1rem; /* 16px */' },
        { name: 'mb-4', property: 'margin-bottom: 1rem; /* 16px */' },
        { name: 'ml-4', property: 'margin-left: 1rem; /* 16px */' },
        { name: 'm-5', property: 'margin: 1.25rem; /* 20px */' },
        {
          name: 'mx-5',
          property: 'margin-left: 1.25rem; /* 20px */ margin-right: 1.25rem; /* 20px */',
        },
        {
          name: 'my-5',
          property: 'margin-top: 1.25rem; /* 20px */ margin-bottom: 1.25rem; /* 20px */',
        },
        { name: 'mt-5', property: 'margin-top: 1.25rem; /* 20px */' },
        { name: 'mr-5', property: 'margin-right: 1.25rem; /* 20px */' },
        { name: 'mb-5', property: 'margin-bottom: 1.25rem; /* 20px */' },
        { name: 'ml-5', property: 'margin-left: 1.25rem; /* 20px */' },
        { name: 'm-6', property: 'margin: 1.5rem; /* 24px */' },
        {
          name: 'mx-6',
          property: 'margin-left: 1.5rem; /* 24px */ margin-right: 1.5rem; /* 24px */',
        },
        {
          name: 'my-6',
          property: 'margin-top: 1.5rem; /* 24px */ margin-bottom: 1.5rem; /* 24px */',
        },
        { name: 'mt-6', property: 'margin-top: 1.5rem; /* 24px */' },
        { name: 'mr-6', property: 'margin-right: 1.5rem; /* 24px */' },
        { name: 'mb-6', property: 'margin-bottom: 1.5rem; /* 24px */' },
        { name: 'ml-6', property: 'margin-left: 1.5rem; /* 24px */' },
        { name: 'm-7', property: 'margin: 1.75rem; /* 28px */' },
        {
          name: 'mx-7',
          property: 'margin-left: 1.75rem; /* 28px */ margin-right: 1.75rem; /* 28px */',
        },
        {
          name: 'my-7',
          property: 'margin-top: 1.75rem; /* 28px */ margin-bottom: 1.75rem; /* 28px */',
        },
        { name: 'mt-7', property: 'margin-top: 1.75rem; /* 28px */' },
        { name: 'mr-7', property: 'margin-right: 1.75rem; /* 28px */' },
        { name: 'mb-7', property: 'margin-bottom: 1.75rem; /* 28px */' },
        { name: 'ml-7', property: 'margin-left: 1.75rem; /* 28px */' },
        { name: 'm-8', property: 'margin: 2rem; /* 32px */' },
        { name: 'mx-8', property: 'margin-left: 2rem; /* 32px */ margin-right: 2rem; /* 32px */' },
        { name: 'my-8', property: 'margin-top: 2rem; /* 32px */ margin-bottom: 2rem; /* 32px */' },
        { name: 'mt-8', property: 'margin-top: 2rem; /* 32px */' },
        { name: 'mr-8', property: 'margin-right: 2rem; /* 32px */' },
        { name: 'mb-8', property: 'margin-bottom: 2rem; /* 32px */' },
        { name: 'ml-8', property: 'margin-left: 2rem; /* 32px */' },
        { name: 'm-9', property: 'margin: 2.25rem; /* 36px */' },
        {
          name: 'mx-9',
          property: 'margin-left: 2.25rem; /* 36px */ margin-right: 2.25rem; /* 36px */',
        },
        {
          name: 'my-9',
          property: 'margin-top: 2.25rem; /* 36px */ margin-bottom: 2.25rem; /* 36px */',
        },
        { name: 'mt-9', property: 'margin-top: 2.25rem; /* 36px */' },
        { name: 'mr-9', property: 'margin-right: 2.25rem; /* 36px */' },
        { name: 'mb-9', property: 'margin-bottom: 2.25rem; /* 36px */' },
        { name: 'ml-9', property: 'margin-left: 2.25rem; /* 36px */' },
        { name: 'm-10', property: 'margin: 2.5rem; /* 40px */' },
        {
          name: 'mx-10',
          property: 'margin-left: 2.5rem; /* 40px */ margin-right: 2.5rem; /* 40px */',
        },
        {
          name: 'my-10',
          property: 'margin-top: 2.5rem; /* 40px */ margin-bottom: 2.5rem; /* 40px */',
        },
        { name: 'mt-10', property: 'margin-top: 2.5rem; /* 40px */' },
        { name: 'mr-10', property: 'margin-right: 2.5rem; /* 40px */' },
        { name: 'mb-10', property: 'margin-bottom: 2.5rem; /* 40px */' },
        { name: 'ml-10', property: 'margin-left: 2.5rem; /* 40px */' },
      ],
    },
    {
      label: 'Width',
      description: 'Utilities for setting the width of an element.',
      classes: [
        { name: 'w-0', property: 'width: 0px' },
        { name: 'w-px', property: 'width: 1px' },
        { name: 'w-0.5', property: 'width: 0.125rem; /* 2px */' },
        { name: 'w-1', property: 'width: 0.25rem; /* 4px */' },
        { name: 'w-1.5', property: 'width: 0.375rem; /* 6px */' },
        { name: 'w-2', property: 'width: 0.5rem; /* 8px */' },
        { name: 'w-2.5', property: 'width: 0.625rem; /* 10px */' },
        { name: 'w-3', property: 'width: 0.75rem; /* 12px */' },
        { name: 'w-3.5', property: 'width: 0.875rem; /* 14px */' },
        { name: 'w-4', property: 'width: 1rem; /* 16px */' },
        { name: 'w-5', property: 'width: 1.25rem; /* 20px */' },
        { name: 'w-6', property: 'width: 1.5rem; /* 24px */' },
        { name: 'w-7', property: 'width: 1.75rem; /* 28px */' },
        { name: 'w-8', property: 'width: 2rem; /* 32px */' },
        { name: 'w-9', property: 'width: 2.25rem; /* 36px */' },
        { name: 'w-10', property: 'width: 2.5rem; /* 40px */' },
        { name: 'w-11', property: 'width: 2.75rem; /* 44px */' },
        { name: 'w-12', property: 'width: 3rem; /* 48px */' },
        { name: 'w-14', property: 'width: 3.5rem; /* 56px */' },
        { name: 'w-16', property: 'width: 4rem; /* 64px */' },
        { name: 'w-20', property: 'width: 5rem; /* 80px */' },
        { name: 'w-24', property: 'width: 6rem; /* 96px */' },
        { name: 'w-28', property: 'width: 7rem; /* 112px */' },
        { name: 'w-32', property: 'width: 8rem; /* 128px */' },
        { name: 'w-36', property: 'width: 9rem; /* 144px */' },
        { name: 'w-40', property: 'width: 10rem; /* 160px */' },
        { name: 'w-44', property: 'width: 11rem; /* 176px */' },
        { name: 'w-48', property: 'width: 12rem; /* 192px */' },
        { name: 'w-52', property: 'width: 13rem; /* 208px */' },
        { name: 'w-56', property: 'width: 14rem; /* 224px */' },
        { name: 'w-60', property: 'width: 15rem; /* 240px */' },
        { name: 'w-64', property: 'width: 16rem; /* 256px */' },
        { name: 'w-72', property: 'width: 18rem; /* 288px */' },
        { name: 'w-80', property: 'width: 20rem; /* 320px */' },
        { name: 'w-96', property: 'width: 24rem; /* 384px */' },
        { name: 'w-auto', property: 'width: auto' },
        { name: 'w-1/2', property: 'width: 50%' },
        { name: 'w-1/3', property: 'width: 33.333333%' },
        { name: 'w-2/3', property: 'width: 66.666667%' },
        { name: 'w-1/4', property: 'width: 25%' },
        { name: 'w-2/4', property: 'width: 50%' },
        { name: 'w-3/4', property: 'width: 75%' },
        { name: 'w-1/5', property: 'width: 20%' },
        { name: 'w-2/5', property: 'width: 40%' },
        { name: 'w-3/5', property: 'width: 60%' },
        { name: 'w-4/5', property: 'width: 80%' },
        { name: 'w-1/6', property: 'width: 16.666667%' },
        { name: 'w-2/6', property: 'width: 33.333333%' },
        { name: 'w-3/6', property: 'width: 50%' },
        { name: 'w-4/6', property: 'width: 66.666667%' },
        { name: 'w-5/6', property: 'width: 83.333333%' },
        { name: 'w-1/12', property: 'width: 8.333333%' },
        { name: 'w-2/12', property: 'width: 16.666667%' },
        { name: 'w-3/12', property: 'width: 25%' },
        { name: 'w-4/12', property: 'width: 33.333333%' },
        { name: 'w-5/12', property: 'width: 41.666667%' },
        { name: 'w-6/12', property: 'width: 50%' },
        { name: 'w-7/12', property: 'width: 58.333333%' },
        { name: 'w-8/12', property: 'width: 66.666667%' },
        { name: 'w-9/12', property: 'width: 75%' },
        { name: 'w-10/12', property: 'width: 83.333333%' },
        { name: 'w-11/12', property: 'width: 91.666667%' },
        { name: 'w-full', property: 'width: 100%' },
        { name: 'w-screen', property: 'width: 100vw' },
        { name: 'w-min', property: 'width: min-content' },
        { name: 'w-max', property: 'width: max-content' },
        { name: 'w-fit', property: 'width: fit-content' },
      ],
    },
    {
      label: 'Height',
      description: 'Utilities for setting the height of an element.',
      classes: [
        { name: 'h-0', property: 'height: 0px' },
        { name: 'h-px', property: 'height: 1px' },
        { name: 'h-0.5', property: 'height: 0.125rem; /* 2px */' },
        { name: 'h-1', property: 'height: 0.25rem; /* 4px */' },
        { name: 'h-1.5', property: 'height: 0.375rem; /* 6px */' },
        { name: 'h-2', property: 'height: 0.5rem; /* 8px */' },
        { name: 'h-2.5', property: 'height: 0.625rem; /* 10px */' },
        { name: 'h-3', property: 'height: 0.75rem; /* 12px */' },
        { name: 'h-3.5', property: 'height: 0.875rem; /* 14px */' },
        { name: 'h-4', property: 'height: 1rem; /* 16px */' },
        { name: 'h-5', property: 'height: 1.25rem; /* 20px */' },
        { name: 'h-6', property: 'height: 1.5rem; /* 24px */' },
        { name: 'h-7', property: 'height: 1.75rem; /* 28px */' },
        { name: 'h-8', property: 'height: 2rem; /* 32px */' },
        { name: 'h-9', property: 'height: 2.25rem; /* 36px */' },
        { name: 'h-10', property: 'height: 2.5rem; /* 40px */' },
        { name: 'h-11', property: 'height: 2.75rem; /* 44px */' },
        { name: 'h-12', property: 'height: 3rem; /* 48px */' },
        { name: 'h-14', property: 'height: 3.5rem; /* 56px */' },
        { name: 'h-16', property: 'height: 4rem; /* 64px */' },
        { name: 'h-20', property: 'height: 5rem; /* 80px */' },
        { name: 'h-24', property: 'height: 6rem; /* 96px */' },
        { name: 'h-28', property: 'height: 7rem; /* 112px */' },
        { name: 'h-32', property: 'height: 8rem; /* 128px */' },
        { name: 'h-36', property: 'height: 9rem; /* 144px */' },
        { name: 'h-40', property: 'height: 10rem; /* 160px */' },
        { name: 'h-44', property: 'height: 11rem; /* 176px */' },
        { name: 'h-48', property: 'height: 12rem; /* 192px */' },
        { name: 'h-52', property: 'height: 13rem; /* 208px */' },
        { name: 'h-56', property: 'height: 14rem; /* 224px */' },
        { name: 'h-60', property: 'height: 15rem; /* 240px */' },
        { name: 'h-64', property: 'height: 16rem; /* 256px */' },
        { name: 'h-72', property: 'height: 18rem; /* 288px */' },
        { name: 'h-80', property: 'height: 20rem; /* 320px */' },
        { name: 'h-96', property: 'height: 24rem; /* 384px */' },
        { name: 'h-auto', property: 'height: auto' },
        { name: 'h-1/2', property: 'height: 50%' },
        { name: 'h-1/3', property: 'height: 33.333333%' },
        { name: 'h-2/3', property: 'height: 66.666667%' },
        { name: 'h-1/4', property: 'height: 25%' },
        { name: 'h-2/4', property: 'height: 50%' },
        { name: 'h-3/4', property: 'height: 75%' },
        { name: 'h-1/5', property: 'height: 20%' },
        { name: 'h-2/5', property: 'height: 40%' },
        { name: 'h-3/5', property: 'height: 60%' },
        { name: 'h-4/5', property: 'height: 80%' },
        { name: 'h-1/6', property: 'height: 16.666667%' },
        { name: 'h-2/6', property: 'height: 33.333333%' },
        { name: 'h-3/6', property: 'height: 50%' },
        { name: 'h-4/6', property: 'height: 66.666667%' },
        { name: 'h-5/6', property: 'height: 83.333333%' },
        { name: 'h-1/12', property: 'height: 8.333333%' },
        { name: 'h-2/12', property: 'height: 16.666667%' },
        { name: 'h-3/12', property: 'height: 25%' },
        { name: 'h-4/12', property: 'height: 33.333333%' },
        { name: 'h-5/12', property: 'height: 41.666667%' },
        { name: 'h-6/12', property: 'height: 50%' },
        { name: 'h-7/12', property: 'height: 58.333333%' },
        { name: 'h-8/12', property: 'height: 66.666667%' },
        { name: 'h-9/12', property: 'height: 75%' },
        { name: 'h-10/12', property: 'height: 83.333333%' },
        { name: 'h-11/12', property: 'height: 91.666667%' },
        { name: 'h-full', property: 'height: 100%' },
        { name: 'h-screen', property: 'height: 100vw' },
        { name: 'h-min', property: 'height: min-content' },
        { name: 'h-max', property: 'height: max-content' },
        { name: 'h-fit', property: 'height: fit-content' },
      ],
    },
    {
      label: 'Text Align',
      description: 'Utilities for controlling the alignment of text.',
      classes: [
        { name: 'text-left', property: 'text-align: left' },
        { name: 'text-center', property: 'text-align: center' },
        { name: 'text-right', property: 'text-align: right' },
        { name: 'text-justify', property: 'text-align: justify' },
        { name: 'text-start', property: 'text-align: start' },
        { name: 'text-end', property: 'text-align: end' },
      ],
    },
    {
      label: 'Text Decoration',
      description: 'Utilities for controlling the decoration of text.',
      classes: [
        { name: 'underline', property: 'text-decoration-line: underline' },
        { name: 'overline', property: 'text-decoration-line: overline' },
        { name: 'line-through', property: 'text-decoration-line: line-through' },
        { name: 'no-underline', property: 'text-decoration-line: none' },
      ],
    },
    {
      label: 'Border',
      description: 'Utilities for controlling the border of an element.',
      classes: [
        { name: 'border', property: 'border-width: 1px' },
        { name: 'border-2', property: 'border-width: 2px' },
        { name: 'border-x-0', property: 'border-left-width: 0px; border-right-width: 0px' },
        { name: 'border-x-2', property: 'border-left-width: 2px; border-right-width: 2px' },
        { name: 'border-x', property: 'border-left-width: 1px; border-right-width: 1px' },
        { name: 'border-y-0', property: 'border-top-width: 0px; border-bottom-width: 0px' },
        { name: 'border-y-2', property: 'border-top-width: 2px; border-bottom-width: 2px' },
        { name: 'border-y', property: 'border-top-width: 1px; border-bottom-width: 1px' },
        { name: 'border-t-0', property: 'border-top-width: 0px' },
        { name: 'border-t-2', property: 'border-top-width: 2px' },
        { name: 'border-t', property: 'border-top-width: 1px' },
        { name: 'border-r-0', property: 'border-right-width: 0px' },
        { name: 'border-r-2', property: 'border-right-width: 2px' },
        { name: 'border-r', property: 'border-right-width: 1px' },
        { name: 'border-b-0', property: 'border-bottom-width: 0px' },
        { name: 'border-b-2', property: 'border-bottom-width: 2px' },
        { name: 'border-b', property: 'border-bottom-width: 1px' },
        { name: 'border-l-0', property: 'border-left-width: 0px' },
        { name: 'border-l-2', property: 'border-left-width: 2px' },
        { name: 'border-l', property: 'border-left-width: 1px' },
        { name: 'border-solid', property: 'border-style: solid' },
        { name: 'border-dashed', property: 'border-style: dashed' },
        { name: 'border-dotted', property: 'border-style: dotted' },
        { name: 'border-double', property: 'border-style: double' },
        { name: 'border-hidden', property: 'border-style: hidden' },
        { name: 'border-none', property: 'border-style: none' },
      ],
    },
    {
      label: 'Opacity',
      description: 'Utilities for controlling the opacity of an element.',
      classes: [
        { name: 'opacity-0', property: 'opacity: 0' },
        { name: 'opacity-5', property: 'opacity: 0.05' },
        { name: 'opacity-10', property: 'opacity: 0.1' },
        { name: 'opacity-20', property: 'opacity: 0.2' },
        { name: 'opacity-25', property: 'opacity: 0.25' },
        { name: 'opacity-30', property: 'opacity: 0.3' },
        { name: 'opacity-40', property: 'opacity: 0.4' },
        { name: 'opacity-50', property: 'opacity: 0.5' },
        { name: 'opacity-60', property: 'opacity: 0.6' },
        { name: 'opacity-70', property: 'opacity: 0.7' },
        { name: 'opacity-75', property: 'opacity: 0.75' },
        { name: 'opacity-80', property: 'opacity: 0.8' },
        { name: 'opacity-90', property: 'opacity: 0.9' },
        { name: 'opacity-95', property: 'opacity: 0.95' },
        { name: 'opacity-100', property: 'opacity: 1' },
      ],
    },
  ];
}
